import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Latex from 'react-latex';

import Aos from 'aos'
import 'aos/dist/aos.css'

import "./QuestionSlider.css";

import QuestionEdit from '../../QuestionCreate/QuestionEdit';
import { canCheckQuestionResponse, hasQuestionEditAuthority, hasQuestionMarkFeature, hasQuestionResponses, prefetchAnswers, securedDeleteQuestionById, securedFetchQuestionById, securedFetchSolutionByQuestionId, waitForHints } from '../../../../services/QuestionService';
import { UnauthorizedError } from '../../../../services/Errors';
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../../../services/UserService';
import { navigateCallbackOptions } from '../../../../services/AuthenticationService';
import { checkResponseCorrectness, hasQuestionMetadata, securedCreateBulkStudentQuestionResponses, securedCreateStudentQuestionResponse, securedDeleteStudentResponsesByFilter, securedFetchQuestionMetadatasByFilter, securedFetchStudentQuestionResponsesByFilter, securedUpdateQuestionMetadatas } from '../../../../services/UserQuestionService';

import { FaRegCheckCircle, FaRegEye } from "react-icons/fa";
import { BiShow, BiHide } from 'react-icons/bi';
import { MdOutlineRadioButtonUnchecked, MdPlaylistAdd, MdPlaylistAddCheck } from "react-icons/md";
import { AiOutlineSave } from "react-icons/ai";
import { RiEdit2Fill } from 'react-icons/ri';
import ForwardTimer from '../../../Common/ForwardTimer';
import TimerButton from '../../../Common/TimerButton';
import { IoMdStar, IoMdStarOutline } from 'react-icons/io';
import { securedCreateTestQuestionAction, securedFetchTestStudentQuestionActionsByFilter } from '../../../../services/TestStudentAction';
import { TEST_CREATE_TYPE_BASIC } from '../../../../services/TestService';
import { getHmacUrl, replaceImagesInObject, roundFloat } from '../../../../services/CommonService';
import ImageUpload from '../../../Common/ImageUpload';
import ImagePreview from '../../../Common/ImagePreview';

const QuestionSlide = ({ questionId, basicInfo = null, basicImages = null, inputQuestionData = null, testId = null, testStatus = null, showImages = false, showAnswers = false, onQuestionDelete = null, studentResponse = null, onTestQuestionResponseChange = null, user = ADMIN_USER_TYPE }) => {

    const options = ['A', 'B', 'C', 'D'];
    const [questionData, setQuestionData] = useState(null);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [correctAnswers, setCorrectAnswers] = useState(null);
    const [answerText, setAnswerText] = useState("");
    const [solution, setSolution] = useState(null);
    const [solutionStepsShown, setSolutionStepsShown] = useState(0);
    const [showSolution, setShowSolution] = useState(false);
    const [shownAllSolution, setShownAllSolution] = useState(false);
    const [checkedAnswerResponse, setCheckedAnswerResponse] = useState(null);
    const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [isEditQuestionOpen, setEditQuestionOpen] = useState(false);
    const [isResponseSaved, setResponseSaved] = useState(false);
    const [isResponseUnchecked, setResponseUnchecked] = useState(false);
    const [isAnswerShown, setIsAnswerShown] = useState(false);
    const [timePassed, setTimePassed] = useState(0);
    const [isShowNextHintButtonVisible, setShowNextHintButtonVisible] = useState(false);
    const [isWideImage, setWideImage] = useState(null);
    const [solutionImages, setSolutionImages] = useState([]);
    const [solutionImageFilenames, setSolutionImageFilenames] = useState([]);

    const navigate = useNavigate();

    const handleImageLoad = (event) => {
        const { naturalWidth, naturalHeight } = event.target;
        const ratio = naturalWidth / naturalHeight;
        if (ratio > 1) {
            setWideImage("wide");
        } else {
            setWideImage("long");
        }
    }

    const handleSelectionChange = (answerId) => {
        if (questionData.question_subtype === 'single choice') {
            setSelectedAnswers([answerId]);
            return;
        }
        setSelectedAnswers(prevSelected => {
            if (prevSelected.includes(answerId)) {
                return prevSelected.filter(id => id !== answerId);
            } else {
                return [...prevSelected, answerId];
            }
        });
    };

    const fetchQuestion = async (questionId, filter) => {
        let question = await securedFetchQuestionById('standalone', questionId, filter, navigateCallbackOptions(navigate));

        if (hasQuestionMetadata(user)) {
            const questionMetadata = await securedFetchQuestionMetadatasByFilter(user, 'standalone', { question_ids: [questionId] }, navigateCallbackOptions(navigate));
            if (questionMetadata === null) {
                return;
            }
            let metadata = {};
            if (questionMetadata.data.length > 0) {
                metadata = questionMetadata.data[0];
            }
            question = { ...question, metadata: metadata };
        }
        return question;
    }

    const makeAnswerTextSubmit = (text) => {
        if (questionData.question_subtype === 'numerical') {
            return roundFloat(parseFloat(text));
        }
        return text;
    }

    const makeAnswerData = () => {
        const answer = {
            question_id: questionData.id,
            evaluate_correctness: (testId === null),
            test_id: testId
        }

        if (questionData.question_subtype === 'numerical') {
            if (answerText.length > 0) {
                answer['student_question_response_answers'] = [{ numerical_response: makeAnswerTextSubmit(answerText) }];
            }
            return answer;
        };
        if (questionData.question_subtype === 'subjective') {
            if (answerText.length > 0) {
                answer['student_question_response_answers'] = [{ text_response: makeAnswerTextSubmit(answerText) }];
            }
            return answer;
        };
        if (selectedAnswers.length > 0) {
            answer['student_question_response_answers'] = selectedAnswers.filter(selectedAnswer => questionData.answers.map(answer => answer.answer.id).includes(selectedAnswer)).map(selectedAnswer => ({ answer_id: selectedAnswer }));
        } else {
            answer['student_question_response_answers'] = []
        }
        return answer;
    }

    const handleMarkStar = async (event) => {
        event.stopPropagation();
        const newIsStarMarked = questionData.metadata.is_star_marked ? false : true;
        const updateMarkResponse = await securedUpdateQuestionMetadatas(user, 'standalone', { is_star_marked: newIsStarMarked, question_id: questionData.id }, navigateCallbackOptions(navigate));
        if (updateMarkResponse === null) {
            return;
        }
        const newMetadata = questionData.metadata ? { ...questionData.metadata, is_star_marked: newIsStarMarked } : null;
        setQuestionData({ ...questionData, metadata: newMetadata });
    }

    const handleMarkForReview = async (event) => {
        event.stopPropagation();
        const newIsMarkedForReview = questionData.metadata.is_review_marked ? false : true;
        const updateMarkResponse = await securedUpdateQuestionMetadatas(user, 'standalone', { is_review_marked: newIsMarkedForReview, question_id: questionData.id }, navigateCallbackOptions(navigate));
        if (updateMarkResponse === null) {
            return;
        }
        const newMetadata = questionData.metadata ? { ...questionData.metadata, is_review_marked: newIsMarkedForReview } : null;
        setQuestionData({ ...questionData, metadata: newMetadata });
    }

    const handleCheckAnswer = async () => {
        const question = await fetchQuestion(questionId, { show_correct_answer: true });
        const fetchedCorrectAnswerIndices = question.answers
            .map((answer, index) => (answer.is_correct ? index : -1))
            .filter(index => index !== -1);
        setCorrectAnswers(fetchedCorrectAnswerIndices.map(index => question.answers[index].answer.id));
        setQuestionData(question);
        if (!canCheckQuestionResponse(questionData.question_subtype)) {
            setIsAnswerCorrect(false);
            return;
        }
        const studentResponse = makeAnswerData();
        if (!studentResponse['student_question_response_answers']) {
            return;
        }
        const response = await securedCreateBulkStudentQuestionResponses({ student_question_responses: [makeAnswerData()] }, navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        const responseIds = response.student_question_responses.map((studentQuestionResponse) => studentQuestionResponse.student_question_response_id);

        const checkedResponsesResponse = await securedFetchStudentQuestionResponsesByFilter({ ids: responseIds, latest_response_only: true }, navigateCallbackOptions(navigate));
        if (checkedResponsesResponse === null) {
            return;
        }
        const checkedResponse = checkedResponsesResponse.data;
        setCheckedAnswerResponse(checkedResponse[0]);

        const checkResponse = checkResponseCorrectness(checkedResponse[0]);
        if (checkResponse) {
            setIsAnswerCorrect(true);
            if (solution !== null) {
                setSolutionStepsShown(solution.solution_steps.length);
            }
            return;
        }
        setIsAnswerCorrect(checkResponse);
    };

    const doPrefetchAnswers = () => {
        return prefetchAnswers(user) || testStatus === 'FINISHED';
    }

    const enableSolution = () => {
        const solutionEnabled = testId === null || testStatus === 'FINISHED';
        return solutionEnabled;
    }

    const getAnswerText = (studentResponse) => {
        if (!studentResponse || !studentResponse.response_answers || studentResponse.response_answers.length === 0) {
            return '';
        }
        if (studentResponse.response_answers[0].text_response != null) {
            return studentResponse.response_answers[0].text_response;
        }
        if (studentResponse.response_answers[0].numerical_response != null) {
            return studentResponse.response_answers[0].numerical_response;
        }
    }

    const fetchAndSetResponses = async () => {
        if (studentResponse) {
            setSelectedAnswers(studentResponse.response_answers.filter(responseAnswer => (responseAnswer.answer_id !== null)).map(responseAnswer => (responseAnswer.answer_id)));
            setAnswerText(getAnswerText(studentResponse));
            return;
        }
        if (prefetchAnswers(user)) {
            return null;
        }
        const filter = {};
        if (testId) {
            filter['test_ids'] = [testId];
        }
        filter['question_ids'] = [questionId];
        if (testId === null) {
            filter['latest_response_only'] = true;
        } else {
            filter['latest_action_only'] = true;
        }
        let fetchedResponses = null;
        if (testId === null) {
            fetchedResponses = await securedFetchStudentQuestionResponsesByFilter(filter, navigateCallbackOptions(navigate));
            if (fetchedResponses === null) {
                return;
            }
            fetchedResponses = fetchedResponses.data;
        }
        else {
            const fetchedActions = await securedFetchTestStudentQuestionActionsByFilter(TEST_CREATE_TYPE_BASIC, filter, navigateCallbackOptions(navigate));
            if (fetchedActions === null) {
                return;
            }
            fetchedResponses = fetchedActions.data.filter(action => action.student_question_response).map(action => action.student_question_response);
        }
        const fetchedQuestionResponses = fetchedResponses.filter(fetchedResponse => fetchedResponse.question_id === questionId);
        if (fetchedQuestionResponses.length === 0) {
            setSelectedAnswers([]);
            setAnswerText('');
            return;
        }
        const lastResponse = fetchedQuestionResponses.filter(fetchedResponse => fetchedResponse.question_id === questionId)[0];

        const initSelectedAnswers = fetchedQuestionResponses.filter(fetchedResponse => fetchedResponse.question_id === questionId)[0].response_answers.filter(responseAnswer => (responseAnswer.answer_id !== null)).map(responseAnswer => (responseAnswer.answer_id));
        const initAnswerText = getAnswerText(fetchedQuestionResponses[0]);
        setSelectedAnswers(initSelectedAnswers);
        setAnswerText(initAnswerText);
    }

    useEffect(() => {
        (async () => {
            let question = inputQuestionData;
            if (!inputQuestionData) {
                question = await fetchQuestion(questionId, { show_correct_answer: doPrefetchAnswers() });

            }
            await fetchAndSetResponses();
            setQuestionData(question);
            if (doPrefetchAnswers()) {
                const fetchedCorrectAnswerIndices = question.answers
                    .map((answer, index) => (answer.is_correct ? index : -1))
                    .filter(index => index !== -1);
                setCorrectAnswers(fetchedCorrectAnswerIndices.map(index => question.answers[index].answer.id));
            }
            setResponseSaved(false);
            setResponseUnchecked(false);
            setShowSolution(false);
            setSolutionStepsShown(0);
            if (testId !== null && testStatus === 'STARTED' && user === STUDENT_USER_TYPE) {
                const data = {
                    test_id: testId,
                    question_id: questionId,
                    is_visited: true
                }
                const markVisited = await securedCreateTestQuestionAction(TEST_CREATE_TYPE_BASIC, testId, data, navigateCallbackOptions(navigate));
                if (markVisited === null) {
                    return;
                }
            }
        })();
    }, [questionId, inputQuestionData]);


    const onEditQuestion = async () => {
        const question = await fetchQuestion(questionId, { show_correct_answer: doPrefetchAnswers() });
        await fetchAndSetResponses();
        setQuestionData(question);
        if (doPrefetchAnswers()) {
            const fetchedCorrectAnswerIndices = question.answers
                .map((answer, index) => (answer.is_correct ? index : -1))
                .filter(index => index !== -1);
            setCorrectAnswers(fetchedCorrectAnswerIndices.map(index => question.answers[index].answer.id));
        }
        if (solution) {
            await loadSolutionIfNotLoaded(true);
        }
    }

    const showDifficulty = () => {
        if (questionData.question_difficulty === null) return '';

        return questionData.question_difficulty.toUpperCase();
    };

    const showId = () => {
        if (questionData.topics.length === 0) return '';

        return `${questionData.question_type === 'paragraph' ? 'Q-' : ''}${questionData.topics[0].topic.subject.subject_name[0]}${questionData.id}`;
    };

    const isRealNumber = (str) => {
        const allowedStarts = ["-", "+", ".", "-.", "+."];
        if (allowedStarts.includes(str)) {
            return true;
        }
        return !isNaN(str) && !isNaN(parseFloat(str));
    }

    const handleAnswerTextChange = (value, isNumeric = false) => {
        if (value === '') {
            setAnswerText('');
        }
        if (isNumeric && !isRealNumber(value)) {
            return;
        }
        setAnswerText(value);
    }

    const renderSubjectiveData = (currentQuestionData) => {
        if (currentQuestionData.question_subtype !== 'subjective') {
            return;
        }
        return (
            <>
                <ImageUpload title='Solution Images' images={solutionImages} selectImages={setSolutionImages} label={'solution'} maxSize={1} setImageFileNames={setSolutionImageFilenames} />
                <ImagePreview images={solutionImages} selectImages={setSolutionImages} imageFileNames={solutionImageFilenames} />
            </>
        )
    }
    const renderAnswer = (currentQuestionData, answer, index) => {
        let isCorrectAnswer = false;

        if (['single choice', 'multi choice'].includes(currentQuestionData.question_subtype)) {
            isCorrectAnswer = correctAnswers ? correctAnswers.includes(answer.answer.id) : false;
            const isAllStepsShown = solution ? solutionStepsShown === solution.solution_steps.length : false;
            return (
                <div className="option flex" id={`${!prefetchAnswers(user) && isCorrectAnswer && ((isAnswerCorrect || isAllStepsShown || isAnswerShown) || testStatus === "FINISHED") ? "correct-answer" : ""}`}>
                    <input
                        className="answer-check"
                        type={questionData.question_subtype === 'single choice' ? 'radio' : 'checkbox'}
                        value={answer.answer.id}
                        checked={prefetchAnswers(user) ? isCorrectAnswer : selectedAnswers.includes(answer.answer.id)}
                        onChange={(event) => {
                            event.stopPropagation();
                            handleSelectionChange(answer.answer.id)
                        }}
                        disabled={prefetchAnswers(user) || (testId && testStatus !== 'STARTED')}
                    />
                    <label className="answer-label">({options[index]}) </label>

                    <Latex>{answer.answer.answer_text || ''}</Latex>

                </div >
            );
        }


        if (currentQuestionData.question_subtype === 'subjective' && (!answer.answer.answer_text || answer.answer.answer_text.length === 0)) {
            return;
        }

        const inputType = currentQuestionData.question_subtype === 'numerical' ? 'number' : currentQuestionData.question_subtype === 'subjective' ? null : 'text';
        const isNumeric = (inputType === 'number');
        return (
            <div className='subjective'>
                {prefetchAnswers(user) ? (
                    <Latex>{isNumeric ? (answer.answer.min_value === answer.answer.max_value ? (`${answer.answer.min_value}` || '') : `Between ${answer.answer.min_value} to ${answer.answer.max_value}`) : (answer.answer.answer_text || '')}</Latex>
                ) : (
                    <>
                        {inputType !== null && (
                            <input
                                type='text'
                                value={answerText}
                                inputMode={isNumeric ? 'numeric' : 'text'}
                                onChange={(e) => handleAnswerTextChange(e.target.value, isNumeric)}
                                disabled={prefetchAnswers(user) || (testId && testStatus !== 'STARTED')}
                                placeholder="0.00"
                                aria-describedby="float-number"
                                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />)}
                    </>
                    // <input
                    //     className="subjective-input"
                    //     type='text'
                    //     value={answerText}
                    //     inputMode={isNumeric ? 'numeric' : 'text'}
                    //     onChange={(e) => handleAnswerTextChange(e.target.value, isNumeric)}
                    //     disabled={prefetchAnswers(user) || (testId && testStatus !== 'STARTED')}
                    // />)
                )}
                {!prefetchAnswers(user) && correctAnswers && questionData.answers[0].answer.answer_text != null ? (<div className="subjective-given-answer"><Latex>{`The answer is ${questionData.answers[0].answer.answer_text || ''}`}</Latex></div>) : <></>}
                {!prefetchAnswers(user) && correctAnswers && questionData.answers[0].answer.min_value != null && questionData.answers[0].answer.min_value !== questionData.answers[0].answer.max_value ? (<div className="subjective-given-answer"><Latex>{`The answer is between ${questionData.answers[0].answer.min_value} and ${questionData.answers[0].answer.max_value != null ? questionData.answers[0].answer.max_value : ''}`}</Latex></div>) : <></>}
                {!prefetchAnswers(user) && correctAnswers && questionData.answers[0].answer.min_value != null && questionData.answers[0].answer.min_value === questionData.answers[0].answer.max_value ? (<div className="subjective-given-answer"><Latex>{`The answer is ${questionData.answers[0].answer.min_value != null ? questionData.answers[0].answer.min_value : ''}`}</Latex></div>) : <></>}

            </div>
        )
    }

    const renderSolutionButton = () => {
        if (!showSolution) {
            return (
                <button className="solution-btn flex" onClick={handleCheckSolution}>
                    <BiShow className="icon" />
                    {waitForHints(user) ? 'Show Hint' : 'Show Solution'}
                </button>
            );
        } else {
            return (
                <button className="solution-btn flex" onClick={handleHideSolution}>
                    <BiHide className="icon" />
                    Hide
                </button>
            );
        }
    }

    const handleCheckNextHint = async () => {
        setSolutionStepsShown(solutionStepsShown + 1);
        if (solutionStepsShown + 1 === solution.solution_steps.length && correctAnswers == null) {
            const question = await fetchQuestion(questionId, { show_correct_answer: true });
            const fetchedCorrectAnswerIndices = question.answers
                .map((answer, index) => (answer.is_correct ? index : -1))
                .filter(index => index !== -1);
            setCorrectAnswers(fetchedCorrectAnswerIndices.map(index => question.answers[index].answer.id));
        }
        setShowNextHintButtonVisible(false);
    }

    const handleTimerEnd = () => {
        setShowNextHintButtonVisible(true);
    }

    const renderSolutionSteps = () => {
        if (!solution.solution_steps) {
            return;
        }
        return (
            <>
                {solution.solution_steps && (solution.solution_steps.map((text, index) =>
                    renderSolutionStep(index)
                ))}
                {solution.solution_steps.length > solutionStepsShown && (
                    <button className="timer-btn" onClick={handleCheckNextHint}>
                        Show Next Hint
                    </button>)}
            </>);
    }

    const renderSolution = () => {

        if (!showSolution) {
            return;
        }
        if (!solution || (solution.solution_text.length === 0 && solution.images.length === 0)) {
            return (
                <div className="solution-text flex" >
                    Solution is not provided for this question.
                </div>
            );
        }
        return (
            <>
                {renderSolutionSteps()}
                {(!solution.solution_steps || solution.solution_steps.length === solutionStepsShown) && (
                    <>
                        <div className="solution-step">SOLUTION</div>
                        <div className="solution-text flex">
                            <Latex>{solution.solution_text}</Latex>
                        </div>

                        {showImages && solution.images && (
                            <div className="question-image solution-image flex">
                                {solution.images.map((img, index) => (
                                    <img key={index} src={img.image.url} alt="Solution related" draggable={false} />
                                ))}
                            </div>)}
                    </>
                )}
            </>
        )
    }

    const renderSolutionStep = (index) => {

        if (index >= solutionStepsShown) {
            return;
        }

        return (
            <>
                <div className="solution-step">STEP {index + 1}</div>
                <div className="solution-text">
                    <Latex>{solution.solution_steps[index].step_text}</Latex>
                </div>
            </>
        );
    }

    const renderCheckedResponse = () => {
        if (!checkedAnswerResponse) {
            return;
        }

        if (isAnswerCorrect) {
            // setIsAnswerCorrect(false);
            return (
                <p className="msg" id="correctAnswer" data-aos="fade-out">
                    Hurray! You got the right answer!
                </p>
            );
        } else {
            return (
                <p className="msg" id="wrongAnswer" data-aos="fade-out">
                    Sorry, your answer is incorrect.
                    <br /> Please try again.
                </p>
            );
        }
    }

    const renderSavedResponse = () => {

        if (!isResponseSaved) {
            return;
        }

        if (isResponseSaved) {
            // setIsAnswerCorrect(false);
            return (
                <p className="msg" id="savedAnswer" data-aos="fade-out">
                    Response saved!
                </p>
            );
        }
    }

    const handleShowAnswer = async () => {
        const question = await fetchQuestion(questionId, { show_correct_answer: true });
        const fetchedCorrectAnswerIndices = question.answers
            .map((answer, index) => (answer.is_correct ? index : -1))
            .filter(index => index !== -1);
        setCorrectAnswers(fetchedCorrectAnswerIndices.map(index => question.answers[index].answer.id));
        setIsAnswerShown(true);
    }

    const renderUncheckedResponse = () => {

        if (!isResponseUnchecked) {
            return;
        }

        if (isResponseUnchecked) {
            return (
                <p className="msg" id="uncheckedAnswer" data-aos="fade-out">
                    Response Unchecked!
                </p>
            );
        }
    }

    const onQuestionDeleteClick = async () => {
        const response = await securedDeleteQuestionById('standalone', questionId);
        if (response !== null && onQuestionDelete) {
            onQuestionDelete();
        }
    }

    const handleSaveResponse = async () => {
        const responseAnswer
            = makeAnswerData();
        if (!responseAnswer['student_question_response_answers']) {
            return;
        }
        if (responseAnswer['student_question_response_answers'].length === 0) {
            await handleUncheckResponse();
            return;
        }
        const studentAction = { test_id: testId, student_question_response: makeAnswerData(), is_saved: true, question_id: questionId }
        const response = await securedCreateTestQuestionAction(TEST_CREATE_TYPE_BASIC, testId, studentAction, navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        setResponseSaved(true);
        setResponseUnchecked(false);
        if (onTestQuestionResponseChange) {
            onTestQuestionResponseChange(questionId, 'SAVE');
        }
    }

    const handleUncheckResponse = async () => {
        const response = await securedCreateTestQuestionAction(TEST_CREATE_TYPE_BASIC, testId, { question_id: questionId, test_id: testId, is_cleared: true }, navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        setResponseSaved(false);
        setResponseUnchecked(true);
        setSelectedAnswers([]);
        setAnswerText('');
        if (onTestQuestionResponseChange) {
            onTestQuestionResponseChange(questionId, 'UNCHECK');
        }
    }

    const handleCheckSolution = async () => {
        const loadedSolution = await loadSolutionIfNotLoaded();
        if (solutionStepsShown === 0 && loadedSolution && loadedSolution.solution_steps) {
            if (waitForHints(user) && !isAnswerCorrect) {
                setSolutionStepsShown(Math.min(solutionStepsShown + 1, loadedSolution.solution_steps.length));
            }
            else {
                setSolutionStepsShown(loadedSolution.solution_steps.length);
            }
        }
        setShowSolution(true);
    }

    const loadSolutionIfNotLoaded = async (force = false) => {
        if (!solution || force || solution.question_id !== questionId) {
            let response = await securedFetchSolutionByQuestionId(questionData.id, navigateCallbackOptions(navigate));
            if (response === null || response.status === 404) {
                return null;
            }
            setSolution(response);
            setSolutionStepsShown(0);
            return response;
        }
        return solution;
    }

    const handleHideSolution = async () => {
        setShowSolution(false);
    }

    const handleEditQuestionClick = async () => {
        await loadSolutionIfNotLoaded();
        setEditQuestionOpen(true);
    }

    const renderQuestionMetadata = () => {
        if (testId !== null || !hasQuestionMarkFeature(user) || questionData.question_type !== 'standalone') {
            return;
        }
        return (
            <div className="question-saved" >
                <>
                    <button className="saved-btn" id="star-btn" onClick={handleMarkStar}>
                        {questionData.metadata && questionData.metadata.is_star_marked ? (<IoMdStar className="icon" />) : (<IoMdStarOutline className="icon" />)}
                    </button>

                    <button className="saved-btn" id="review-btn" onClick={handleMarkForReview}>
                        {questionData.metadata && questionData.metadata.is_review_marked ? (<MdPlaylistAddCheck className="icon" />) : (<MdPlaylistAdd className="icon" />)}
                    </button>
                </>
            </div>);
    }
    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, [])

    if (!questionData) {
        return <p className="msg">Loading...</p>;  // Or some other loading indicator
    }

    return (
        <div className="question-slide flex">
            <div className="question-info flex">
                {questionData.topics.length > 0 && (user !== STUDENT_USER_TYPE || testId === null) && (
                    <span className="question-id" id={questionData.topics[0].topic.subject.subject_name}> {showId()} </span>
                )}
                {!testId && (
                    <>

                        {/* Displaying Difficulty */}
                        {questionData.question_difficulty && (
                            <span className="question-difficulty" id={questionData.question_difficulty}>
                                {showDifficulty()}
                            </span>
                        )}
                        {renderQuestionMetadata()}

                        {hasQuestionEditAuthority(user) && questionData.question_type === 'standalone' && (
                            <button className="edit-btn" onClick={async (event) => {
                                event.stopPropagation();
                                await handleEditQuestionClick();
                            }}>
                                <RiEdit2Fill className="icon" />
                            </button>
                        )}
                        <QuestionEdit inputQuestionData={{ ...questionData, solution: solution }} isOpen={isEditQuestionOpen} onRequestClose={() => setEditQuestionOpen(false)} user={user} onEditQuestion={onEditQuestion} />

                        {/* Displaying Question Solving Time */}
                        {!prefetchAnswers(user) && questionData.question_type === 'standalone' && (
                            <ForwardTimer timeVar={timePassed} setTimeVar={setTimePassed} />
                        )}

                    </>
                )}
            </div>

            {basicInfo && (
                <div className="question-text">
                    <Latex>{basicInfo}</Latex>
                </div>
            )}

            {(showImages && basicImages) && (
                <div className="question-image">
                    {basicImages.map((img, index) => (
                        <img key={index} src={img.image.url} alt="Question related" />
                    ))}
                </div>
            )}

            {/* Displaying the Question Text */}
            {questionData.question_text && (
                <div className="question-text">
                    <Latex>{questionData.question_text}</Latex>
                </div >
            )}

            {/* Displaying the Question Images */}
            {(showImages && questionData.images.length > 0) && (
                <div className={questionData.question_text.length === 0 ? 'question-image-only' : 'question-image'}>
                    {questionData.images.map((img, index) => (
                        <img
                            key={index}
                            src={img.image.url}
                            alt="Question related"
                            onLoad={handleImageLoad}
                            className={isWideImage}
                        />
                    ))}
                </div>
            )}

            {/* Displaying the Answers */}
            {showAnswers && (
                <div className="answers flex">
                    {questionData.answers.map((answer, index) => (
                        <span key={answer.answer.id} className="answer-container">
                            {renderAnswer(questionData, answer, index)}
                        </span>
                    ))}
                </div>
            )}

            {/* During Practice */}
            {!prefetchAnswers(user) && testId === null && (!isAnswerCorrect && !isAnswerShown && (solution == null || solution.solution_steps.length > solutionStepsShown)) ? (
                <>
                    <div className="check-answer-btns">

                        <button className="btn flex" id="check-answer" onClick={handleCheckAnswer}>
                            Check
                            <FaRegCheckCircle className="icon" />
                        </button>
                        <button className="btn flex" id="check-answer" onClick={handleShowAnswer}>
                            Show Answer
                            <FaRegEye className="icon" />
                        </button>

                    </div>
                    {renderCheckedResponse()}
                </>
            ) : (<>{renderCheckedResponse()}</>)}

            {/* During Test */}
            {!prefetchAnswers(user) && testId !== null && testStatus === 'STARTED' && (
                <div className="answerResponse">
                    {renderUncheckedResponse()}
                    {renderSavedResponse()}

                    <div className="btns flex">
                        <button className="btn flex" onClick={handleUncheckResponse}>
                            Uncheck <MdOutlineRadioButtonUnchecked className="icon" />
                        </button>

                        <button className="btn flex" onClick={handleSaveResponse}>
                            Save <AiOutlineSave className="icon" />
                        </button>
                    </div>
                </div>
            )}

            {/* Show Solution Only When The Answer is Correct  */}
            {
                enableSolution()
                && (
                    <div className="solution flex">
                        {renderSolutionButton()}
                        {renderSolution()}
                    </div>
                )
            }

            {/* {testId === null && hasQuestionDeleteAuthority(user) && (
                <button className="delete-btn flex" onClick={(event) => {
                    event.stopPropagation();
                    setShowPopup(true);
                }}>
                    Delete <RiDeleteBin5Line className="icon" />
                </button>
            )}

            {showPopup && (
                <div className="delete-popup flex">
                    <h3> DELETE the question?</h3>
                    <div className="btns flex">
                        <button className="btn flex" onClick={(event) => {
                            event.stopPropagation();
                            setShowPopup(false);
                        }}>
                            <RiArrowGoBackFill className="icon" />
                        </button>
                        <button className="btn flex" onClick={(event) => {
                            event.stopPropagation();
                            onQuestionDeleteClick();
                        }}>
                            <RiDeleteBin5Line className="icon" />
                        </button>
                    </div>
                </div>
            )} */}

        </div >
    )
};

export default QuestionSlide;
